@import url("https://fonts.googleapis.com/css?family=Audiowide|Sofia|Trirong");
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  box-sizing: border-box;
}

// header
.header {
  background-color: #272643;
  color: #fff;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  padding: 0px 95px 0px 15px;
  font-family: "Sofia";
  li{
    list-style:none;
  }
  .nav-right{
     .nav-list{
     padding:15px 10px 5px 10px;
    }
  }
}

// main
.main {
  width:100%;
  max-width: 1200px;
  height: 520px;
  background-color: #fff;
  margin: 0 auto;
  padding:15px 15px;
  .profile_img{
    margin-top: 20px;
    border-radius: 50%;
    width: 180px;
    height: 180px;
  }
  .introduction {
    .hi {
      font-size: 60px;
      font-family: "Sofia";
    }
    .name {
      color: #2c698d;
      border-bottom: 8px solid #272643;
      font-size: 60px;
      font-family: "Sofia";
    }
  }

  .typewrite {
    color: rgb(61, 58, 58);
    text-decoration: none;
  }
}


// about me
.about-container {
  width: 100%;
  height: 480px;
  margin-top: 10px;
  margin-bottom: 40px;
  border: #2c698d 0.8px solid;
  color: #fff;
  display:flex;
  flex-wrap:wrap;
     @media screen and (max-width:880px){
        display:block;
      }
     @media screen and (max-width:588px){
        margin-bottom: 410px;
      }
  .stack-container{
      background-color: #272643;
      width:60%;   
      @media screen and (max-width:880px){
         width:100%; 
      }
  }
  .about-img-bg{
  position:relative;
  height:100%;
  width:40%;
  background-size:cover;
  background-position:0 0;
  background-repeat:repeat-x;
  animation:animate 15s linear infinite;
   @media screen and (max-width:880px){
         width:100%; 
      }
}

@keyframes animate{
  from{
    background-position:0 0;
  }
  to{
    background-position:100% 0;
  }
}
  .about-title {
    font-size: 2.2rem;
    border-bottom: 5px solid #2c698d;
  }
  .describe {
    padding:10px 20px;
    line-height:35px;
    font-size:1.2rem;
  }
    
    .button-row-1, .button-row-2,.button-row-3{
      .button-col{
        margin-left:15px;
        margin-top:8px;
      }
    }
   button {
    width: auto;
    outline:none;
    border: none;
    height: 40px;
    border-radius: 10px;
    font-size: 17px;
  }
 
}
.about-nav, .connect, .portfolio-nav{
  cursor:pointer;
  text-decoration:none;
  color:#fff;
  &:hover{
    opacity:.8;
      color:#fff;
  }
}
.hidden-detail{
 height:100%;
}

// portfolio
.portfolio {
  width:100%;
  max-width: 1400px;
  margin: 0 auto;
  margin-bottom: 80px;
  padding:0 15px;
  a, a:visited, a:hover{
  text-decoration:none;
  color:#000;
}
   @media screen and (max-width:880px){
       margin-top:420px;
      }
   @media screen and (max-width:588px){
       margin-top:520px;
      }
   @media screen and (max-width:510px){
       margin-top:620px;
      }
   @media screen and (max-width:394px){
       margin-top:690px;
      }
   @media screen and (max-width:330px){
       margin-top:720px;
      }
   @media screen and (max-width:304px){
       margin-top:820px;
      }
   @media screen and (max-width:288px){
       margin-top:870px;
      }
     button {
    width: auto;
    outline:none;
    border: none;
    height: 40px;
    border-radius: 10px;
    font-size: 17px;
    margin-left:15px;
    margin-top:5px;
  }
  .project-header{
    font-size: 2rem;
    font-weight: 500;
    border-bottom: #2c698d solid 5px;

  }
   .project-card {
      .content{
        border-radius: 5%;
          border:0.2px solid transparent;
          height:480px;
          // text-align:center;
          &:hover {
            box-shadow: 2px 2px 12px rgba(47, 47, 47, 0.4);
          }
      }
    }
}


// footer
.footer {
  width: 100%;
  background-color: #272643;
  color: #fff;
  text-align: center;
 padding-top:30px;
  .social-icon-container {
    display: flex;
    padding: 2px 10px 10px 10px;
    justify-content: center;
    align-items:center;
    flex-wrap:wrap;
    .social-rounded-container {
      border-radius: 50%;
      border: 1px rgb(190, 187, 187) solid;
      width: 80px;
      height: 60px;
      color: #000000;
      display:flex;
      justify-content: center;
      align-items:center;
      padding: 10px;
      &:hover {
        background-color: #fff;
        .icon{
          color:#272643;
        }
      }
      .icon {
        color: #fff;
        font-size: 40px !important;

      }
    
  }
  }
}
